import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import test from '../images/test.png';

const Testimonials = () => {
  // const pagination = {
  //   clickable: true,
  // };
  return (
    <main className='bg-[#010917] pt-[60px] md:pt-[40px] h-[600px] md:h-[880px] lg:pt-[70px] xl:pt-[120px] lg:h-[920px] xl:h-[890px]'>
      <h1 className='text-[#FBE810] font text-[16px] md:text-[20px] text-center font-bold leading-[60px] tracking-[10px] uppercase'>
        testimonials
      </h1>

      <h4 className='text-white text-[22px] md:text-[30px] lg:text-[40px] text-center leading-normal md:leading-[60px] font-normal capitalize'>
        What our customers are saying
      </h4>
      <section className='mac:max-w-[83rem] px-5 iphone:px-0 iphone:max-w-[21.5rem] md:max-w-[45rem] lg:max-w-[60rem] xl:max-w-[75rem] mx-auto pt-[62px] md:pt-[100px]'>
        <Swiper
          navigation={true}
          pagination={true}
          modules={[Navigation, Pagination]}
          className='mySwiper'
        >
          <SwiperSlide>
            <main className='flex justify-center items-center pb-16 md:pb-20'>
              <article className='bg-[#00AAFA] w-[263.875px] h-[245.965px] md:w-[485.649px] md:h-[472.687px] xl:w-[827.27px] xl:h-[380.359px] rounded-2xl transform rotate-[0.983deg]'>
                <section className='w-[260.354px] h-[256.638px] md:w-[479.17px] md:h-[472.33px] xl:w-[816.712px] xl:h-[378.242px]  px-5 md:px-10 pt-[27.08px] md:py-[49.83px] mx-auto rounded-2xl rounded-b-2xl bg-[#0E1523] transform xl:-rotate-[2deg] -rotate-[2.43deg]'>
                  <figure className='flex flex-col items-center justify-center'>
                    <img
                      src={test}
                      alt='testimonial'
                      className='md:h-auto md:w-auto h-[43.46px]  w-[43.46px]'
                    />
                    <figcaption className='flex gap-2 pt-[12.1px] md:pt-[22.23px]'>
                      {/* 1 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 2 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 3 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 4 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 5 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </figcaption>
                    <p className='text-center font pt-[11.8px] flex md:pt-[22.23px] leading-[14.127px] text-[#FCFCFC] text-[9.62px] md:text-[20px] xl:text-lg md:leading-[26px] xl:rotate-1 font-medium'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='-mt-2 flex xl:hidden md:-mt-40 xl:-mt-80 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M6.51574 8.71383C7.34076 9.05133 7.97827 9.59509 8.42828 10.3451C8.91578 11.0576 9.15954 11.9201 9.15954 12.9327C9.15954 14.2827 8.72828 15.3889 7.86577 16.2515C7.00325 17.114 5.93448 17.5452 4.65946 17.5452C3.30943 17.5452 2.20316 17.114 1.34065 16.2515C0.478133 15.3889 0.046875 14.2827 0.046875 12.9327C0.046875 12.2576 0.121876 11.6014 0.271879 10.9639C0.459382 10.3264 0.796888 9.37009 1.2844 8.09507L4.2657 0.107422H8.76578L6.51574 8.71383ZM18.441 8.71383C19.266 9.05133 19.9035 9.59509 20.3535 10.3451C20.841 11.0576 21.0848 11.9201 21.0848 12.9327C21.0848 14.2827 20.6535 15.3889 19.791 16.2515C18.9285 17.114 17.8597 17.5452 16.5847 17.5452C15.2346 17.5452 14.1284 17.114 13.2659 16.2515C12.4033 15.3889 11.9721 14.2827 11.9721 12.9327C11.9721 12.2576 12.0471 11.6014 12.1971 10.9639C12.3846 10.3264 12.7221 9.37009 13.2096 8.09507L16.1909 0.107422H20.691L18.441 8.71383Z'
                          fill='#00AAFA'
                        />
                      </svg>
                      I was skeptical about starting my own online business, but
                      JayKay Edwards made it so easy! The platform is
                      user-friendly, and the support team is always there to
                      help. My store is up and running smoothly, and I couldn't
                      be happier.
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='mt-[4.5rem] flex xl:hidden md:mt-36 xl:mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M14.6171 8.71285C13.7921 9.05036 13.1545 9.59412 12.7045 10.3441C12.217 11.0566 11.9733 11.9192 11.9733 12.9317C11.9733 14.2817 12.4045 15.388 13.267 16.2505C14.1296 17.113 15.1983 17.5443 16.4734 17.5443C17.8234 17.5443 18.9296 17.113 19.7922 16.2505C20.6547 15.388 21.0859 14.2817 21.0859 12.9317C21.0859 12.2567 21.0109 11.6004 20.8609 10.9629C20.6734 10.3254 20.3359 9.36911 19.8484 8.09409L16.8671 0.106445H12.367L14.6171 8.71285ZM2.69185 8.71285C1.86684 9.05036 1.22933 9.59412 0.77932 10.3441C0.291811 11.0566 0.0480556 11.9192 0.0480556 12.9317C0.0480556 14.2817 0.479313 15.388 1.34183 16.2505C2.20434 17.113 3.27311 17.5443 4.54814 17.5443C5.89816 17.5443 7.00443 17.113 7.86695 16.2505C8.72946 15.388 9.16072 14.2817 9.16072 12.9317C9.16072 12.2567 9.08572 11.6004 8.93572 10.9629C8.74821 10.3254 8.41071 9.36911 7.9232 8.09409L4.94189 0.106445H0.441814L2.69185 8.71285Z'
                          fill='#00AAFA'
                        />
                      </svg>
                    </p>
                    <p className='text-[#FCFCFC] font pt-[22.6px] md:pt-[42.26px] xl:pt-[37px] leading-[10.332px] md:leading-[19.015px] font-normal text-[7.653px] md:text-[14.085px] text-center'>
                      FLORENCE F.
                      <br />
                      <span className='xl:font-extralight xl:text-[12px] xl:leading-[19px]'>
                        Lagos, Nigeria
                      </span>
                    </p>
                  </figure>
                </section>
              </article>
            </main>
          </SwiperSlide>
          <SwiperSlide>
            <main className='flex justify-center items-center pb-16 md:pb-20'>
              <article className='bg-[#00AAFA] w-[263.875px] h-[245.965px] md:w-[485.649px] md:h-[472.687px] xl:w-[827.27px] xl:h-[380.359px] rounded-2xl transform rotate-[0.983deg]'>
                <section className='w-[260.354px] h-[256.638px] md:w-[479.17px] md:h-[472.33px] xl:w-[816.712px] xl:h-[368.242px]  px-5 md:px-10 pt-[27.08px] md:py-[49.83px] mx-auto rounded-2xl rounded-b-2xl bg-[#0E1523] transform xl:-rotate-[2deg] -rotate-[2.43deg]'>
                  <figure className='flex flex-col items-center justify-center'>
                    <img
                      src={test}
                      alt='testimonial'
                      className='md:h-auto md:w-auto h-[43.46px]  w-[43.46px]'
                    />
                    <figcaption className='flex gap-2 pt-[12.1px] md:pt-[22.23px]'>
                      {/* 1 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 2 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 3 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 4 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 5 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </figcaption>
                    <p className='text-center font pt-[11.8px] flex md:pt-[22.23px] xl:rotate-1 leading-[14.127px] text-[#FCFCFC] text-[9.62px] md:text-[20px] xl:text-lg md:leading-[26px] font-medium'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='-mt-2 flex xl:hidden md:-mt-40 xl:-mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M6.51574 8.71383C7.34076 9.05133 7.97827 9.59509 8.42828 10.3451C8.91578 11.0576 9.15954 11.9201 9.15954 12.9327C9.15954 14.2827 8.72828 15.3889 7.86577 16.2515C7.00325 17.114 5.93448 17.5452 4.65946 17.5452C3.30943 17.5452 2.20316 17.114 1.34065 16.2515C0.478133 15.3889 0.046875 14.2827 0.046875 12.9327C0.046875 12.2576 0.121876 11.6014 0.271879 10.9639C0.459382 10.3264 0.796888 9.37009 1.2844 8.09507L4.2657 0.107422H8.76578L6.51574 8.71383ZM18.441 8.71383C19.266 9.05133 19.9035 9.59509 20.3535 10.3451C20.841 11.0576 21.0848 11.9201 21.0848 12.9327C21.0848 14.2827 20.6535 15.3889 19.791 16.2515C18.9285 17.114 17.8597 17.5452 16.5847 17.5452C15.2346 17.5452 14.1284 17.114 13.2659 16.2515C12.4033 15.3889 11.9721 14.2827 11.9721 12.9327C11.9721 12.2576 12.0471 11.6014 12.1971 10.9639C12.3846 10.3264 12.7221 9.37009 13.2096 8.09507L16.1909 0.107422H20.691L18.441 8.71383Z'
                          fill='#00AAFA'
                        />
                      </svg>
                      I was skeptical about starting my own online business, but
                      JayKay Edwards made it so easy! The platform is
                      user-friendly, and the support team is always there to
                      help. My store is up and running smoothly, and I couldn't
                      be happier.
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='mt-[4.5rem] xl:mt-0 flex xl:hidden md:mt-36 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M14.6171 8.71285C13.7921 9.05036 13.1545 9.59412 12.7045 10.3441C12.217 11.0566 11.9733 11.9192 11.9733 12.9317C11.9733 14.2817 12.4045 15.388 13.267 16.2505C14.1296 17.113 15.1983 17.5443 16.4734 17.5443C17.8234 17.5443 18.9296 17.113 19.7922 16.2505C20.6547 15.388 21.0859 14.2817 21.0859 12.9317C21.0859 12.2567 21.0109 11.6004 20.8609 10.9629C20.6734 10.3254 20.3359 9.36911 19.8484 8.09409L16.8671 0.106445H12.367L14.6171 8.71285ZM2.69185 8.71285C1.86684 9.05036 1.22933 9.59412 0.77932 10.3441C0.291811 11.0566 0.0480556 11.9192 0.0480556 12.9317C0.0480556 14.2817 0.479313 15.388 1.34183 16.2505C2.20434 17.113 3.27311 17.5443 4.54814 17.5443C5.89816 17.5443 7.00443 17.113 7.86695 16.2505C8.72946 15.388 9.16072 14.2817 9.16072 12.9317C9.16072 12.2567 9.08572 11.6004 8.93572 10.9629C8.74821 10.3254 8.41071 9.36911 7.9232 8.09409L4.94189 0.106445H0.441814L2.69185 8.71285Z'
                          fill='#00AAFA'
                        />
                      </svg>
                    </p>
                    <p className='text-[#FCFCFC] font pt-[22.6px] md:pt-[42.26px] xl:pt-[37px] leading-[10.332px] md:leading-[19.015px] font-normal text-[7.653px] md:text-[14.085px] text-center'>
                      FLORENCE F.
                      <br />
                      <span className='xl:font-extralight xl:text-[12px] xl:leading-[19px]'>
                        Lagos, Nigeria
                      </span>
                    </p>
                  </figure>
                </section>
              </article>
            </main>
          </SwiperSlide>
          <SwiperSlide>
            <main className='flex justify-center items-center pb-16 md:pb-20'>
              <article className='bg-[#00AAFA] w-[263.875px] h-[245.965px] md:w-[485.649px] md:h-[472.687px] xl:w-[827.27px] xl:h-[380.359px] rounded-2xl transform rotate-[0.983deg]'>
                <section className='w-[260.354px] h-[256.638px] md:w-[479.17px] md:h-[472.33px] xl:w-[816.712px] xl:h-[368.242px]  px-5 md:px-10 pt-[27.08px] md:py-[49.83px] mx-auto rounded-2xl rounded-b-2xl bg-[#0E1523] transform xl:-rotate-[2deg] -rotate-[2.43deg]'>
                  <figure className='flex flex-col items-center justify-center'>
                    <img
                      src={test}
                      alt='testimonial'
                      className='md:h-auto md:w-auto h-[43.46px]  w-[43.46px]'
                    />
                    <figcaption className='flex gap-2 pt-[12.1px] md:pt-[22.23px]'>
                      {/* 1 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 2 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 3 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 4 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 5 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </figcaption>
                    <p className='text-center font pt-[11.8px] flex md:pt-[22.23px] xl:rotate-1 leading-[14.127px] text-[#FCFCFC] text-[9.62px] md:text-[20px] xl:text-lg md:leading-[26px] font-medium'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='-mt-2 flex xl:hidden md:-mt-40 xl:-mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M6.51574 8.71383C7.34076 9.05133 7.97827 9.59509 8.42828 10.3451C8.91578 11.0576 9.15954 11.9201 9.15954 12.9327C9.15954 14.2827 8.72828 15.3889 7.86577 16.2515C7.00325 17.114 5.93448 17.5452 4.65946 17.5452C3.30943 17.5452 2.20316 17.114 1.34065 16.2515C0.478133 15.3889 0.046875 14.2827 0.046875 12.9327C0.046875 12.2576 0.121876 11.6014 0.271879 10.9639C0.459382 10.3264 0.796888 9.37009 1.2844 8.09507L4.2657 0.107422H8.76578L6.51574 8.71383ZM18.441 8.71383C19.266 9.05133 19.9035 9.59509 20.3535 10.3451C20.841 11.0576 21.0848 11.9201 21.0848 12.9327C21.0848 14.2827 20.6535 15.3889 19.791 16.2515C18.9285 17.114 17.8597 17.5452 16.5847 17.5452C15.2346 17.5452 14.1284 17.114 13.2659 16.2515C12.4033 15.3889 11.9721 14.2827 11.9721 12.9327C11.9721 12.2576 12.0471 11.6014 12.1971 10.9639C12.3846 10.3264 12.7221 9.37009 13.2096 8.09507L16.1909 0.107422H20.691L18.441 8.71383Z'
                          fill='#00AAFA'
                        />
                      </svg>
                      I was skeptical about starting my own online business, but
                      JayKay Edwards made it so easy! The platform is
                      user-friendly, and the support team is always there to
                      help. My store is up and running smoothly, and I couldn't
                      be happier.
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='mt-[4.5rem] flex xl:hidden md:mt-36 xl:mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M14.6171 8.71285C13.7921 9.05036 13.1545 9.59412 12.7045 10.3441C12.217 11.0566 11.9733 11.9192 11.9733 12.9317C11.9733 14.2817 12.4045 15.388 13.267 16.2505C14.1296 17.113 15.1983 17.5443 16.4734 17.5443C17.8234 17.5443 18.9296 17.113 19.7922 16.2505C20.6547 15.388 21.0859 14.2817 21.0859 12.9317C21.0859 12.2567 21.0109 11.6004 20.8609 10.9629C20.6734 10.3254 20.3359 9.36911 19.8484 8.09409L16.8671 0.106445H12.367L14.6171 8.71285ZM2.69185 8.71285C1.86684 9.05036 1.22933 9.59412 0.77932 10.3441C0.291811 11.0566 0.0480556 11.9192 0.0480556 12.9317C0.0480556 14.2817 0.479313 15.388 1.34183 16.2505C2.20434 17.113 3.27311 17.5443 4.54814 17.5443C5.89816 17.5443 7.00443 17.113 7.86695 16.2505C8.72946 15.388 9.16072 14.2817 9.16072 12.9317C9.16072 12.2567 9.08572 11.6004 8.93572 10.9629C8.74821 10.3254 8.41071 9.36911 7.9232 8.09409L4.94189 0.106445H0.441814L2.69185 8.71285Z'
                          fill='#00AAFA'
                        />
                      </svg>
                    </p>
                    <p className='text-[#FCFCFC] font pt-[22.6px] md:pt-[42.26px] xl:pt-[37px] leading-[10.332px] md:leading-[19.015px] font-normal text-[7.653px] md:text-[14.085px] text-center'>
                      FLORENCE F.
                      <br />
                      <span className='xl:font-extralight xl:text-[12px] xl:leading-[19px]'>
                        Lagos, Nigeria
                      </span>
                    </p>
                  </figure>
                </section>
              </article>
            </main>
          </SwiperSlide>
          <SwiperSlide>
            <main className='flex justify-center items-center pb-16 md:pb-20'>
              <article className='bg-[#00AAFA] w-[263.875px] h-[245.965px] md:w-[485.649px] md:h-[472.687px] xl:w-[827.27px] xl:h-[380.359px] rounded-2xl transform rotate-[0.983deg]'>
                <section className='w-[260.354px] h-[256.638px] md:w-[479.17px] md:h-[472.33px] xl:w-[816.712px] xl:h-[368.242px]  px-5 md:px-10 pt-[27.08px] md:py-[49.83px] mx-auto rounded-2xl rounded-b-2xl bg-[#0E1523] transform xl:-rotate-[2deg] -rotate-[2.43deg]'>
                  <figure className='flex flex-col items-center justify-center'>
                    <img
                      src={test}
                      alt='testimonial'
                      className='md:h-auto md:w-auto h-[43.46px]  w-[43.46px]'
                    />
                    <figcaption className='flex gap-2 pt-[12.1px] md:pt-[22.23px]'>
                      {/* 1 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 2 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 3 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 4 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 5 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </figcaption>
                    <p className='text-center font pt-[11.8px] flex md:pt-[22.23px] xl:rotate-1 leading-[14.127px] text-[#FCFCFC] text-[9.62px] md:text-[20px] xl:text-lg md:leading-[26px] font-medium'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='-mt-2 font flex xl:hidden xl:-mt-0 md:-mt-40 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M6.51574 8.71383C7.34076 9.05133 7.97827 9.59509 8.42828 10.3451C8.91578 11.0576 9.15954 11.9201 9.15954 12.9327C9.15954 14.2827 8.72828 15.3889 7.86577 16.2515C7.00325 17.114 5.93448 17.5452 4.65946 17.5452C3.30943 17.5452 2.20316 17.114 1.34065 16.2515C0.478133 15.3889 0.046875 14.2827 0.046875 12.9327C0.046875 12.2576 0.121876 11.6014 0.271879 10.9639C0.459382 10.3264 0.796888 9.37009 1.2844 8.09507L4.2657 0.107422H8.76578L6.51574 8.71383ZM18.441 8.71383C19.266 9.05133 19.9035 9.59509 20.3535 10.3451C20.841 11.0576 21.0848 11.9201 21.0848 12.9327C21.0848 14.2827 20.6535 15.3889 19.791 16.2515C18.9285 17.114 17.8597 17.5452 16.5847 17.5452C15.2346 17.5452 14.1284 17.114 13.2659 16.2515C12.4033 15.3889 11.9721 14.2827 11.9721 12.9327C11.9721 12.2576 12.0471 11.6014 12.1971 10.9639C12.3846 10.3264 12.7221 9.37009 13.2096 8.09507L16.1909 0.107422H20.691L18.441 8.71383Z'
                          fill='#00AAFA'
                        />
                      </svg>
                      I was skeptical about starting my own online business, but
                      JayKay Edwards made it so easy! The platform is
                      user-friendly, and the support team is always there to
                      help. My store is up and running smoothly, and I couldn't
                      be happier.
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='mt-[4.5rem] flex xl:hidden md:mt-36 xl:mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M14.6171 8.71285C13.7921 9.05036 13.1545 9.59412 12.7045 10.3441C12.217 11.0566 11.9733 11.9192 11.9733 12.9317C11.9733 14.2817 12.4045 15.388 13.267 16.2505C14.1296 17.113 15.1983 17.5443 16.4734 17.5443C17.8234 17.5443 18.9296 17.113 19.7922 16.2505C20.6547 15.388 21.0859 14.2817 21.0859 12.9317C21.0859 12.2567 21.0109 11.6004 20.8609 10.9629C20.6734 10.3254 20.3359 9.36911 19.8484 8.09409L16.8671 0.106445H12.367L14.6171 8.71285ZM2.69185 8.71285C1.86684 9.05036 1.22933 9.59412 0.77932 10.3441C0.291811 11.0566 0.0480556 11.9192 0.0480556 12.9317C0.0480556 14.2817 0.479313 15.388 1.34183 16.2505C2.20434 17.113 3.27311 17.5443 4.54814 17.5443C5.89816 17.5443 7.00443 17.113 7.86695 16.2505C8.72946 15.388 9.16072 14.2817 9.16072 12.9317C9.16072 12.2567 9.08572 11.6004 8.93572 10.9629C8.74821 10.3254 8.41071 9.36911 7.9232 8.09409L4.94189 0.106445H0.441814L2.69185 8.71285Z'
                          fill='#00AAFA'
                        />
                      </svg>
                    </p>
                    <p className='text-[#FCFCFC] font pt-[22.6px] md:pt-[42.26px] xl:pt-[37px] leading-[10.332px] md:leading-[19.015px] font-normal text-[7.653px] md:text-[14.085px] text-center'>
                      FLORENCE F.
                      <br />
                      <span className='xl:font-extralight xl:text-[12px] xl:leading-[19px]'>
                        Lagos, Nigeria
                      </span>
                    </p>
                  </figure>
                </section>
              </article>
            </main>
          </SwiperSlide>
          <SwiperSlide>
            <main className='flex justify-center items-center'>
              <article className='bg-[#00AAFA] w-[263.875px] h-[245.965px] md:w-[485.649px] md:h-[472.687px] xl:w-[827.27px] xl:h-[380.359px] rounded-2xl transform rotate-[0.983deg]'>
                <section className='w-[260.354px] h-[256.638px] md:w-[479.17px] md:h-[472.33px] xl:w-[816.712px] xl:h-[368.242px]  px-5 md:px-10 pt-[27.08px] md:py-[49.83px] mx-auto rounded-2xl rounded-b-2xl bg-[#0E1523] transform xl:-rotate-[2deg] -rotate-[2.43deg]'>
                  <figure className='flex flex-col items-center justify-center'>
                    <img
                      src={test}
                      alt='testimonial'
                      className='md:h-auto md:w-auto h-[43.46px]  w-[43.46px]'
                    />
                    <figcaption className='flex gap-2 pt-[12.1px] md:pt-[22.23px]'>
                      {/* 1 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 2 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 3 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 4 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* 5 */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='none'
                        className='md:h-auto md:w-auto w-[9.78px] h-[9.78px]'
                      >
                        <g clip-path='url(#clip0_3423_1428)'>
                          <path
                            d='M9.04562 1.23145L6.48625 6.4177L0.765625 7.24457L4.90562 11.2833L3.92687 16.9814L9.04562 14.2927L14.1644 16.9814L13.1856 11.2833L17.3256 7.2502L11.605 6.4177L9.04562 1.23145Z'
                            fill='#F1D334'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_3423_1428'>
                            <rect
                              width='18'
                              height='18'
                              fill='white'
                              transform='translate(0.046875 0.106445)'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </figcaption>
                    <p className='text-center font pt-[11.8px] flex md:pt-[22.23px] xl:rotate-1 leading-[14.127px] text-[#FCFCFC] text-[9.62px] md:text-[20px] xl:text-lg md:leading-[26px] font-medium'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='-mt-2 flex xl:hidden md:-mt-40 xl:-mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M6.51574 8.71383C7.34076 9.05133 7.97827 9.59509 8.42828 10.3451C8.91578 11.0576 9.15954 11.9201 9.15954 12.9327C9.15954 14.2827 8.72828 15.3889 7.86577 16.2515C7.00325 17.114 5.93448 17.5452 4.65946 17.5452C3.30943 17.5452 2.20316 17.114 1.34065 16.2515C0.478133 15.3889 0.046875 14.2827 0.046875 12.9327C0.046875 12.2576 0.121876 11.6014 0.271879 10.9639C0.459382 10.3264 0.796888 9.37009 1.2844 8.09507L4.2657 0.107422H8.76578L6.51574 8.71383ZM18.441 8.71383C19.266 9.05133 19.9035 9.59509 20.3535 10.3451C20.841 11.0576 21.0848 11.9201 21.0848 12.9327C21.0848 14.2827 20.6535 15.3889 19.791 16.2515C18.9285 17.114 17.8597 17.5452 16.5847 17.5452C15.2346 17.5452 14.1284 17.114 13.2659 16.2515C12.4033 15.3889 11.9721 14.2827 11.9721 12.9327C11.9721 12.2576 12.0471 11.6014 12.1971 10.9639C12.3846 10.3264 12.7221 9.37009 13.2096 8.09507L16.1909 0.107422H20.691L18.441 8.71383Z'
                          fill='#00AAFA'
                        />
                      </svg>
                      I was skeptical about starting my own online business, but
                      JayKay Edwards made it so easy! The platform is
                      user-friendly, and the support team is always there to
                      help. My store is up and running smoothly, and I couldn't
                      be happier.
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='25'
                        viewBox='0 0 22 18'
                        fill='none'
                        className='mt-[4.5rem] flex xl:hidden md:mt-36 xl:mt-0 md:h-auto md:w-auto h-[9.475px]'
                      >
                        <path
                          d='M14.6171 8.71285C13.7921 9.05036 13.1545 9.59412 12.7045 10.3441C12.217 11.0566 11.9733 11.9192 11.9733 12.9317C11.9733 14.2817 12.4045 15.388 13.267 16.2505C14.1296 17.113 15.1983 17.5443 16.4734 17.5443C17.8234 17.5443 18.9296 17.113 19.7922 16.2505C20.6547 15.388 21.0859 14.2817 21.0859 12.9317C21.0859 12.2567 21.0109 11.6004 20.8609 10.9629C20.6734 10.3254 20.3359 9.36911 19.8484 8.09409L16.8671 0.106445H12.367L14.6171 8.71285ZM2.69185 8.71285C1.86684 9.05036 1.22933 9.59412 0.77932 10.3441C0.291811 11.0566 0.0480556 11.9192 0.0480556 12.9317C0.0480556 14.2817 0.479313 15.388 1.34183 16.2505C2.20434 17.113 3.27311 17.5443 4.54814 17.5443C5.89816 17.5443 7.00443 17.113 7.86695 16.2505C8.72946 15.388 9.16072 14.2817 9.16072 12.9317C9.16072 12.2567 9.08572 11.6004 8.93572 10.9629C8.74821 10.3254 8.41071 9.36911 7.9232 8.09409L4.94189 0.106445H0.441814L2.69185 8.71285Z'
                          fill='#00AAFA'
                        />
                      </svg>
                    </p>
                    <p className='text-[#FCFCFC] font pt-[22.6px] md:pt-[42.26px] xl:pt-[37px] leading-[10.332px] md:leading-[19.015px] font-normal text-[7.653px] md:text-[14.085px] text-center'>
                      FLORENCE F.
                      <br />
                      <span className='xl:font-extralight xl:text-[12px] xl:leading-[19px]'>
                        Lagos, Nigeria
                      </span>
                    </p>
                  </figure>
                </section>
              </article>
            </main>
          </SwiperSlide>
        </Swiper>
      </section>
    </main>
  );
};

export default Testimonials;
