import React, { useEffect } from 'react';
import Nav from './Nav';
import Hero from './Hero';
import About from './About';
import Choose from './Choose';
import Business from './Business';
import Testimonials from './Testimonials';
import Footer from './Footer';
import Categories from './Categories';
import Experience from './Experience';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div>
      <Nav />
      <Hero />
      <About />
      <Choose />
      <Business />
      <Categories />
      <Testimonials />
      <Experience />
      <Footer />
    </div>
  );
};

export default Home;
